import React from 'react'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../styles/fco.module.css'

export default function Fco(){
    return (
        <Layout>
            <section className={styles.fco}>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        What is FCO?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            The Foreign and Commonwealth Office (FCO) as it used to be known has actually closed and was replaced by the <a href="https://www.gov.uk/government/organisations/foreign-commonwealth-office" target="_blank" rel="noreferrer">Foreign, Commonwealth & Development Office (FCDO)</a>. 
                        </p>
                        <p className="paragraph">
                            The duties of the newly formed FCDO remain the same as outgoing Foreign and Commonwealth Office and it remains the United Kingdom's Ministry of Foreign Affairs. 
                            It is still widely called the Foreign Office and FCO and the <a href="https://www.gov.uk/government/ministers/foreign-secretary" target="_blank" rel="noreferrer">Secretary of State for Foreign and Commonwealth Affairs</a> still heads the FCDO.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <div className={styles.image_container}>
                            <StaticImage
                            src="../images/fco.png"
                            alt="Foreign and Commonwealth Office"
                            placeholder="blurred"
                            layout="fullWidth"
                            />
                        </div>
                    </div>
                </div>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        Can Apostilles issued by the FCO be rejected in the country where they are to be used?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            No. The signatories of the Convention agreed that Apostille Certificates issued in accordance with its requirements must be recognised in the country where they are to be used.
                        </p>
                        <p className="paragraph">
                            If you use our services and you use the document in the country that we have agreed to you in writing accepts Apostille Certificates you will not have problems.
                        </p>
                        <p className="paragraph">
                            We will give you your money back if the Apostille we have done is not accepted in the country that we have confirmed in writing will accept it. 
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            Apostilles may only be questioned and not accepted if their origin cannot be verified or the particulars on the Apostille do not correspond with those in the register kept by the Competent Authority.
                        </p>
                        <p className="paragraph">
                            Naturally, legalisations issued by countries that are not party to the Convention will be rejected in all other States as being contrary to the Convention.
                        </p>
                    </div>
                </div>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        Does the FCO use electronic Apostille?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            No.
                        </p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
